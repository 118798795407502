import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/root/workspace/whale-homepage_AeEf/.nuxt/components.plugin.mjs";
import node_modules__pnpm_nuxt_643_0_0_eslint_648_30_0_ioredis_645_3_1_less_644_1_3_rollup_642_79_1_typescript_644_9_4_node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_pIaV2Tsr4r from "/root/workspace/whale-homepage_AeEf/node_modules/.pnpm/nuxt@3.0.0_eslint@8.30.0_ioredis@5.3.1_less@4.1.3_rollup@2.79.1_typescript@4.9.4/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules__pnpm_nuxt_643_0_0_eslint_648_30_0_ioredis_645_3_1_less_644_1_3_rollup_642_79_1_typescript_644_9_4_node_modules_nuxt_dist_pages_runtime_router_mjs_ZxfIntADso from "/root/workspace/whale-homepage_AeEf/node_modules/.pnpm/nuxt@3.0.0_eslint@8.30.0_ioredis@5.3.1_less@4.1.3_rollup@2.79.1_typescript@4.9.4/node_modules/nuxt/dist/pages/runtime/router.mjs";
import plugins_i18n_ts_VfGcjrvSkj from "/root/workspace/whale-homepage_AeEf/plugins/i18n.ts";
import plugins_sentry_client_ts_shVUlIjFLk from "/root/workspace/whale-homepage_AeEf/plugins/sentry.client.ts";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules__pnpm_nuxt_643_0_0_eslint_648_30_0_ioredis_645_3_1_less_644_1_3_rollup_642_79_1_typescript_644_9_4_node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_pIaV2Tsr4r,
  node_modules__pnpm_nuxt_643_0_0_eslint_648_30_0_ioredis_645_3_1_less_644_1_3_rollup_642_79_1_typescript_644_9_4_node_modules_nuxt_dist_pages_runtime_router_mjs_ZxfIntADso,
  plugins_i18n_ts_VfGcjrvSkj,
  plugins_sentry_client_ts_shVUlIjFLk
]