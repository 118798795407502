export const appCdnURL = "https://jkqj-web-static-shanghai.oss-cn-shanghai.aliyuncs.com/homepage/assets/"

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"},{"charset":"utf-8"},{"name":"keywords","content":"全球雇佣,海外用工,海外发工资,海外雇佣,海外人才招募,国际劳动力解决方案,海外用工政策"},{"name":"description","content":"DG.hire 在全球 150 个国家提供全球雇佣和企业海外落地服务，同时有 50 名法律专家帮助您解决合规安全问题，帮助企业快速招聘和雇佣全球人才。"},{"name":"google-site-verification","content":"PQTCmcQ6vKV0LLrHmyUEKcxnzUfaybX6NrOvjzQhJgw"}],"link":[],"style":[],"script":[],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"