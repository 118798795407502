import validate from "/root/workspace/whale-homepage_AeEf/node_modules/.pnpm/nuxt@3.0.0_eslint@8.30.0_ioredis@5.3.1_less@4.1.3_rollup@2.79.1_typescript@4.9.4/node_modules/nuxt/dist/pages/runtime/validate.mjs";
import encode_45uri_45global from "/root/workspace/whale-homepage_AeEf/middleware/encodeURI.global.ts";
import inherit_45url_45query_45global from "/root/workspace/whale-homepage_AeEf/middleware/inheritUrlQuery.global.ts";
import log_45global from "/root/workspace/whale-homepage_AeEf/middleware/log.global.ts";
export const globalMiddleware = [
  validate,
  encode_45uri_45global,
  inherit_45url_45query_45global,
  log_45global
]
export const namedMiddleware = {}