export enum E_LogLevel {
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
  fatal = 'fatal',
  perf = 'perf',
}

export function access(message: string) {
  process.env.APP_LOGGER !== 'false' && console.info(`[access] ${message}`, );
}

export function info(category: string, message: string, extra?: Record<string, any>) {
  process.env.APP_LOGGER !== 'false' && console.info(`[info] ${category} ${message} ${JSON.stringify(extra) || ''}`);
}

export function perf(key: string, data: Record<string, number>) {
  process.env.APP_LOGGER !== 'false' && console.log(`[performance] ${key} ${JSON.stringify(data)}`);
}

export function warn(key: string, message: string, extra?: Record<string, any>) {
  process.env.APP_LOGGER !== 'false' && console.warn(`[warn] ${key} ${message} ${JSON.stringify(extra) || ''}`);
}

export function error(key: string, err: Error | string, extra?: Record<string, any>) {
  process.env.APP_LOGGER !== 'false' && console.error(`[error] ${key} "${err}" "${err instanceof Error ? err.stack : ''}" ${JSON.stringify(extra) || ''}`);
}
