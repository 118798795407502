import qs from 'qs';
export default defineNuxtRouteMiddleware((to, from) => {
  if (!process.client) {
    return;
  }
  useLogger().track('page_view', {
    router_to_full_path: to.fullPath,
    router_to_path: to.path,
    router_to_query: qs.stringify(to.query),
    router_from: from.fullPath,
  });
});
