<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<script lang="ts" setup>
import { ICountry } from './@types/entities';
import '@/assets/style/main.less';
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();

type IHotCountryList = ICountry & { id: string; priority: number };

onMounted(() => {
  useLogger().track('app_mount');
  const lang = localStorage.getItem('lang') ?? 'zh-CN';
  locale.value = lang;
});

const countryList = ref<IHotCountryList[]>([]);
async function loadCountryList() {
  const res = await fetch('/api/resource/country/hot');
  const { data } = (await res.json()) as { data: IHotCountryList[] };
  countryList.value = data || [];
}
provide('countryList', () => countryList.value);

onMounted(() => {
  loadCountryList();
});
</script>
<style lang="less"></style>
