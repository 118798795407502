import { createI18n } from 'vue-i18n';
import zh from '@/i18n/lang/zh.json';
import en from '@/i18n/lang/en.json';


const messages = {
  'zh-CN':zh,
  'en-US':en
};

export const i18n  = createI18n({
  // 当前语言
  locale: 'zh-CN',
  // 当语言资源不存在时，使用的默认替换语言
  fallbackLocale: 'zh-CN',
  // 通过composition api方式使用，则需要将legacy设置为false
  legacy: false,
  messages,
});
