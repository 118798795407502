/*
 * @Author: luxiangqiang
 * @Date: 2023-02-15 09:27:16
 * @LastEditors: luxiangqiang
 * @LastEditTime: 2023-02-15 14:42:37
 */
import UAParser from 'ua-parser-js';
import qs from 'qs';
import { error as logError } from '~~/common/logger';
import 'object.fromentries';

let globalSensors: any;

const initSensors = async () => {
  const { default: sensors } = await import('@/assets/scripts/sensorsdata.es6.min.js');
  sensors.init({
    // show_log: process.env.NODE_ENV !== 'production',
    show_log: false,
    server_url: 'https://dc.reta-inc.com/dc/v1/event/track',
    // is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
    use_client_time: true,
    send_type: 'ajax',
    heatmap: {
      // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
      clickmap: 'not_collect',
      // 是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
      scroll_notice_map: 'not_collect',
    },
  });
  globalSensors = sensors;
};


function getExtraUrlParams() {
  const urlQueryParams = qs.parse(window.location.search.substring(1));
  const campaignQuery = Object.fromEntries(Object.entries(urlQueryParams).filter(([k]) => k.startsWith('c_')));
  return campaignQuery;
}

export default function useLogger() {
  if (!globalSensors) {
    initSensors();
  }
  return {
    ...globalSensors,
    track(eventName: string, data: Record<string, any>) {
      if (!globalSensors) {
        return;
      }
      try {
        const parser = new UAParser();
        const res = parser.getResult();
        const trackData = {
          $os: res.os.name,
          $os_version: res.os.version,
          $screen_width: document.documentElement.clientWidth,
          $screen_height: document.documentElement.clientHeight,
          $browser: res.browser.name,
          $browser_version: res.browser.version,
          $model: res.device.model,
          ...data,
          ...getExtraUrlParams(),
        };
        return globalSensors.track(eventName, trackData);
      } catch (error) {
        logError('sensor_track', error as Error);
        return globalSensors.track(eventName, data);
      }
    },
  };
}
