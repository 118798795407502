import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47company_45info_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/about/company-info.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47competition_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/about/competition.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47development_45capability_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/about/development-capability/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47free_45consultation_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/about/free-consultation.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47partner_45program_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/about/partner-program.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47activity_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/activity/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47activity_47services_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/activity/services.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47charge_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/charge/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47connect_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/connect/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47dingtalk_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/dingtalk/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47faq_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/faq/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47components_47search_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/help-center/components/search.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47faq_47_91id_93_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/help-center/faq/[id].vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/help-center/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47search_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/help-center/search.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47advance_47components_47advance_45item_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/index/components/advance/components/advance-item.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47advance_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/index/components/advance/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47computer_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/index/components/computer/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47country_45guide_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/index/components/country-guide.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47dialog_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/index/components/dialog.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47evaluation_47components_47evaluation_45item_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/index/components/evaluation/components/evaluation-item.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47evaluation_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/index/components/evaluation/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47home_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/index/components/home.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47hot_45list_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/index/components/hot-list/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47partners_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/index/components/partners/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47service_45introduce_47components_47service_45item_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/index/components/service-introduce/components/service-item.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47service_45introduce_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/index/components/service-introduce/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47tools_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/index/components/tools.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47wellknown_45service_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/index/components/wellknown-service.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/index/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47_91category_93_47_91id_93_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/info/[category]/[id].vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47_91category_93_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/info/[category]/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47detail_47_91guid_93_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/info/detail/[guid].vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47_91keyword_93_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/landing/word/[keyword].vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45content_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/landing/word/components/keyword-content.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45country_45guide_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/landing/word/components/keyword-country-guide.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45question_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/landing/word/components/keyword-question.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45tools_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/landing/word/components/keyword-tools.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47review_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/landing/word/review.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47leads_47validate_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/leads/validate.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47oversea_45employ_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/product/oversea-employ.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47oversea_45recruitment_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/product/oversea-recruitment.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47payroll_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/product/payroll.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47poly_45employ_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/product/poly-employ.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47rank_45list_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/rank-list/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47compensation_45insight_45tool_45detail_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/resource/compensation-insight-tool-detail.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47compensation_45insight_45tool_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/resource/compensation-insight-tool.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47cost_45calculator_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/resource/cost-calculator.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47_91category_93_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/resource/global/[countryName]/[category].vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/resource/global/[countryName]/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47overview_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/resource/global/[countryName]/overview.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47index_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/resource/global/index.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47wage_45calculator_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/resource/wage-calculator.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47showcase_47_91id_93_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/showcase/[id].vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47solution_47global_45legitimate_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/solution/global-legitimate.vue?macro=true";
import { default as _47root_47workspace_47whale_45homepage_AeEf_47pages_47solution_47payroll_46vueMeta } from "/root/workspace/whale-homepage_AeEf/pages/solution/payroll.vue?macro=true";
export default [
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47company_45info_46vueMeta?.name ?? "about-company-info",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47company_45info_46vueMeta?.path ?? "/about/company-info",
    file: "/root/workspace/whale-homepage_AeEf/pages/about/company-info.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47company_45info_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47company_45info_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47company_45info_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/about/company-info.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47competition_46vueMeta?.name ?? "about-competition",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47competition_46vueMeta?.path ?? "/about/competition",
    file: "/root/workspace/whale-homepage_AeEf/pages/about/competition.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47competition_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47competition_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47competition_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/about/competition.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47development_45capability_47index_46vueMeta?.name ?? "about-development-capability",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47development_45capability_47index_46vueMeta?.path ?? "/about/development-capability",
    file: "/root/workspace/whale-homepage_AeEf/pages/about/development-capability/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47development_45capability_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47development_45capability_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47development_45capability_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/about/development-capability/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47free_45consultation_46vueMeta?.name ?? "about-free-consultation",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47free_45consultation_46vueMeta?.path ?? "/about/free-consultation",
    file: "/root/workspace/whale-homepage_AeEf/pages/about/free-consultation.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47free_45consultation_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47free_45consultation_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47free_45consultation_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/about/free-consultation.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47partner_45program_46vueMeta?.name ?? "about-partner-program",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47partner_45program_46vueMeta?.path ?? "/about/partner-program",
    file: "/root/workspace/whale-homepage_AeEf/pages/about/partner-program.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47partner_45program_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47partner_45program_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47about_47partner_45program_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/about/partner-program.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47activity_47index_46vueMeta?.name ?? "activity",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47activity_47index_46vueMeta?.path ?? "/activity",
    file: "/root/workspace/whale-homepage_AeEf/pages/activity/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47activity_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47activity_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47activity_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/activity/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47activity_47services_46vueMeta?.name ?? "activity-services",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47activity_47services_46vueMeta?.path ?? "/activity/services",
    file: "/root/workspace/whale-homepage_AeEf/pages/activity/services.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47activity_47services_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47activity_47services_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47activity_47services_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/activity/services.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47charge_47index_46vueMeta?.name ?? "charge",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47charge_47index_46vueMeta?.path ?? "/charge",
    file: "/root/workspace/whale-homepage_AeEf/pages/charge/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47charge_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47charge_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47charge_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/charge/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47connect_47index_46vueMeta?.name ?? "connect",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47connect_47index_46vueMeta?.path ?? "/connect",
    file: "/root/workspace/whale-homepage_AeEf/pages/connect/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47connect_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47connect_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47connect_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/connect/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47dingtalk_47index_46vueMeta?.name ?? "dingtalk",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47dingtalk_47index_46vueMeta?.path ?? "/dingtalk",
    file: "/root/workspace/whale-homepage_AeEf/pages/dingtalk/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47dingtalk_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47dingtalk_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47dingtalk_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/dingtalk/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47faq_47index_46vueMeta?.name ?? "faq",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47faq_47index_46vueMeta?.path ?? "/faq",
    file: "/root/workspace/whale-homepage_AeEf/pages/faq/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47faq_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47faq_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47faq_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47components_47search_46vueMeta?.name ?? "help-center-components-search",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47components_47search_46vueMeta?.path ?? "/help-center/components/search",
    file: "/root/workspace/whale-homepage_AeEf/pages/help-center/components/search.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47components_47search_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47components_47search_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47components_47search_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/help-center/components/search.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47faq_47_91id_93_46vueMeta?.name ?? "help-center-faq-id",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47faq_47_91id_93_46vueMeta?.path ?? "/help-center/faq/:id",
    file: "/root/workspace/whale-homepage_AeEf/pages/help-center/faq/[id].vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47faq_47_91id_93_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47faq_47_91id_93_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47faq_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/help-center/faq/[id].vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47index_46vueMeta?.name ?? "help-center",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47index_46vueMeta?.path ?? "/help-center",
    file: "/root/workspace/whale-homepage_AeEf/pages/help-center/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/help-center/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47search_46vueMeta?.name ?? "help-center-search",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47search_46vueMeta?.path ?? "/help-center/search",
    file: "/root/workspace/whale-homepage_AeEf/pages/help-center/search.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47search_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47search_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47help_45center_47search_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/help-center/search.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47advance_47components_47advance_45item_46vueMeta?.name ?? "index-components-advance-components-advance-item",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47advance_47components_47advance_45item_46vueMeta?.path ?? "//components/advance/components/advance-item",
    file: "/root/workspace/whale-homepage_AeEf/pages/index/components/advance/components/advance-item.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47advance_47components_47advance_45item_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47advance_47components_47advance_45item_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47advance_47components_47advance_45item_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/index/components/advance/components/advance-item.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47advance_47index_46vueMeta?.name ?? "index-components-advance",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47advance_47index_46vueMeta?.path ?? "//components/advance",
    file: "/root/workspace/whale-homepage_AeEf/pages/index/components/advance/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47advance_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47advance_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47advance_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/index/components/advance/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47computer_47index_46vueMeta?.name ?? "index-components-computer",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47computer_47index_46vueMeta?.path ?? "//components/computer",
    file: "/root/workspace/whale-homepage_AeEf/pages/index/components/computer/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47computer_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47computer_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47computer_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/index/components/computer/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47country_45guide_46vueMeta?.name ?? "index-components-country-guide",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47country_45guide_46vueMeta?.path ?? "//components/country-guide",
    file: "/root/workspace/whale-homepage_AeEf/pages/index/components/country-guide.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47country_45guide_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47country_45guide_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47country_45guide_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/index/components/country-guide.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47dialog_46vueMeta?.name ?? "index-components-dialog",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47dialog_46vueMeta?.path ?? "//components/dialog",
    file: "/root/workspace/whale-homepage_AeEf/pages/index/components/dialog.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47dialog_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47dialog_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47dialog_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/index/components/dialog.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47evaluation_47components_47evaluation_45item_46vueMeta?.name ?? "index-components-evaluation-components-evaluation-item",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47evaluation_47components_47evaluation_45item_46vueMeta?.path ?? "//components/evaluation/components/evaluation-item",
    file: "/root/workspace/whale-homepage_AeEf/pages/index/components/evaluation/components/evaluation-item.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47evaluation_47components_47evaluation_45item_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47evaluation_47components_47evaluation_45item_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47evaluation_47components_47evaluation_45item_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/index/components/evaluation/components/evaluation-item.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47evaluation_47index_46vueMeta?.name ?? "index-components-evaluation",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47evaluation_47index_46vueMeta?.path ?? "//components/evaluation",
    file: "/root/workspace/whale-homepage_AeEf/pages/index/components/evaluation/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47evaluation_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47evaluation_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47evaluation_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/index/components/evaluation/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47home_46vueMeta?.name ?? "index-components-home",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47home_46vueMeta?.path ?? "//components/home",
    file: "/root/workspace/whale-homepage_AeEf/pages/index/components/home.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47home_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47home_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47home_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/index/components/home.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47hot_45list_47index_46vueMeta?.name ?? "index-components-hot-list",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47hot_45list_47index_46vueMeta?.path ?? "//components/hot-list",
    file: "/root/workspace/whale-homepage_AeEf/pages/index/components/hot-list/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47hot_45list_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47hot_45list_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47hot_45list_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/index/components/hot-list/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47partners_47index_46vueMeta?.name ?? "index-components-partners",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47partners_47index_46vueMeta?.path ?? "//components/partners",
    file: "/root/workspace/whale-homepage_AeEf/pages/index/components/partners/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47partners_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47partners_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47partners_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/index/components/partners/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47service_45introduce_47components_47service_45item_46vueMeta?.name ?? "index-components-service-introduce-components-service-item",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47service_45introduce_47components_47service_45item_46vueMeta?.path ?? "//components/service-introduce/components/service-item",
    file: "/root/workspace/whale-homepage_AeEf/pages/index/components/service-introduce/components/service-item.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47service_45introduce_47components_47service_45item_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47service_45introduce_47components_47service_45item_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47service_45introduce_47components_47service_45item_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/index/components/service-introduce/components/service-item.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47service_45introduce_47index_46vueMeta?.name ?? "index-components-service-introduce",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47service_45introduce_47index_46vueMeta?.path ?? "//components/service-introduce",
    file: "/root/workspace/whale-homepage_AeEf/pages/index/components/service-introduce/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47service_45introduce_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47service_45introduce_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47service_45introduce_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/index/components/service-introduce/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47tools_46vueMeta?.name ?? "index-components-tools",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47tools_46vueMeta?.path ?? "//components/tools",
    file: "/root/workspace/whale-homepage_AeEf/pages/index/components/tools.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47tools_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47tools_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47tools_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/index/components/tools.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47wellknown_45service_46vueMeta?.name ?? "index-components-wellknown-service",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47wellknown_45service_46vueMeta?.path ?? "//components/wellknown-service",
    file: "/root/workspace/whale-homepage_AeEf/pages/index/components/wellknown-service.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47wellknown_45service_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47wellknown_45service_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47components_47wellknown_45service_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/index/components/wellknown-service.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47index_46vueMeta?.name ?? "index",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47index_46vueMeta?.path ?? "/",
    file: "/root/workspace/whale-homepage_AeEf/pages/index/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47index_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47_91category_93_47_91id_93_46vueMeta?.name ?? "info-category-id",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47_91category_93_47_91id_93_46vueMeta?.path ?? "/info/:category/:id",
    file: "/root/workspace/whale-homepage_AeEf/pages/info/[category]/[id].vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47_91category_93_47_91id_93_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47_91category_93_47_91id_93_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47_91category_93_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/info/[category]/[id].vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47_91category_93_47index_46vueMeta?.name ?? "info-category",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47_91category_93_47index_46vueMeta?.path ?? "/info/:category",
    file: "/root/workspace/whale-homepage_AeEf/pages/info/[category]/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47_91category_93_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47_91category_93_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47_91category_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/info/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47detail_47_91guid_93_46vueMeta?.name ?? "info-detail-guid",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47detail_47_91guid_93_46vueMeta?.path ?? "/info/detail/:guid",
    file: "/root/workspace/whale-homepage_AeEf/pages/info/detail/[guid].vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47detail_47_91guid_93_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47detail_47_91guid_93_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47info_47detail_47_91guid_93_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/info/detail/[guid].vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47_91keyword_93_46vueMeta?.name ?? "landing-word-keyword",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47_91keyword_93_46vueMeta?.path ?? "/landing/word/:keyword",
    file: "/root/workspace/whale-homepage_AeEf/pages/landing/word/[keyword].vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47_91keyword_93_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47_91keyword_93_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47_91keyword_93_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/landing/word/[keyword].vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45content_46vueMeta?.name ?? "landing-word-components-keyword-content",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45content_46vueMeta?.path ?? "/landing/word/components/keyword-content",
    file: "/root/workspace/whale-homepage_AeEf/pages/landing/word/components/keyword-content.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45content_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45content_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45content_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/landing/word/components/keyword-content.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45country_45guide_46vueMeta?.name ?? "landing-word-components-keyword-country-guide",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45country_45guide_46vueMeta?.path ?? "/landing/word/components/keyword-country-guide",
    file: "/root/workspace/whale-homepage_AeEf/pages/landing/word/components/keyword-country-guide.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45country_45guide_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45country_45guide_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45country_45guide_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/landing/word/components/keyword-country-guide.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45question_46vueMeta?.name ?? "landing-word-components-keyword-question",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45question_46vueMeta?.path ?? "/landing/word/components/keyword-question",
    file: "/root/workspace/whale-homepage_AeEf/pages/landing/word/components/keyword-question.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45question_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45question_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45question_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/landing/word/components/keyword-question.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45tools_46vueMeta?.name ?? "landing-word-components-keyword-tools",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45tools_46vueMeta?.path ?? "/landing/word/components/keyword-tools",
    file: "/root/workspace/whale-homepage_AeEf/pages/landing/word/components/keyword-tools.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45tools_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45tools_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47components_47keyword_45tools_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/landing/word/components/keyword-tools.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47review_46vueMeta?.name ?? "landing-word-review",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47review_46vueMeta?.path ?? "/landing/word/review",
    file: "/root/workspace/whale-homepage_AeEf/pages/landing/word/review.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47review_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47review_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47landing_47word_47review_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/landing/word/review.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47leads_47validate_46vueMeta?.name ?? "leads-validate",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47leads_47validate_46vueMeta?.path ?? "/leads/validate",
    file: "/root/workspace/whale-homepage_AeEf/pages/leads/validate.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47leads_47validate_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47leads_47validate_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47leads_47validate_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/leads/validate.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47oversea_45employ_46vueMeta?.name ?? "product-oversea-employ",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47oversea_45employ_46vueMeta?.path ?? "/product/oversea-employ",
    file: "/root/workspace/whale-homepage_AeEf/pages/product/oversea-employ.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47oversea_45employ_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47oversea_45employ_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47oversea_45employ_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/product/oversea-employ.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47oversea_45recruitment_46vueMeta?.name ?? "product-oversea-recruitment",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47oversea_45recruitment_46vueMeta?.path ?? "/product/oversea-recruitment",
    file: "/root/workspace/whale-homepage_AeEf/pages/product/oversea-recruitment.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47oversea_45recruitment_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47oversea_45recruitment_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47oversea_45recruitment_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/product/oversea-recruitment.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47payroll_46vueMeta?.name ?? "product-payroll",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47payroll_46vueMeta?.path ?? "/product/payroll",
    file: "/root/workspace/whale-homepage_AeEf/pages/product/payroll.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47payroll_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47payroll_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47payroll_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/product/payroll.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47poly_45employ_46vueMeta?.name ?? "product-poly-employ",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47poly_45employ_46vueMeta?.path ?? "/product/poly-employ",
    file: "/root/workspace/whale-homepage_AeEf/pages/product/poly-employ.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47poly_45employ_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47poly_45employ_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47product_47poly_45employ_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/product/poly-employ.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47rank_45list_47index_46vueMeta?.name ?? "rank-list",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47rank_45list_47index_46vueMeta?.path ?? "/rank-list",
    file: "/root/workspace/whale-homepage_AeEf/pages/rank-list/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47rank_45list_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47rank_45list_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47rank_45list_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/rank-list/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47compensation_45insight_45tool_45detail_46vueMeta?.name ?? "resource-compensation-insight-tool-detail",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47compensation_45insight_45tool_45detail_46vueMeta?.path ?? "/resource/compensation-insight-tool-detail",
    file: "/root/workspace/whale-homepage_AeEf/pages/resource/compensation-insight-tool-detail.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47compensation_45insight_45tool_45detail_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47compensation_45insight_45tool_45detail_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47compensation_45insight_45tool_45detail_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/resource/compensation-insight-tool-detail.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47compensation_45insight_45tool_46vueMeta?.name ?? "resource-compensation-insight-tool",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47compensation_45insight_45tool_46vueMeta?.path ?? "/resource/compensation-insight-tool",
    file: "/root/workspace/whale-homepage_AeEf/pages/resource/compensation-insight-tool.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47compensation_45insight_45tool_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47compensation_45insight_45tool_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47compensation_45insight_45tool_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/resource/compensation-insight-tool.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47cost_45calculator_46vueMeta?.name ?? "resource-cost-calculator",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47cost_45calculator_46vueMeta?.path ?? "/resource/cost-calculator",
    file: "/root/workspace/whale-homepage_AeEf/pages/resource/cost-calculator.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47cost_45calculator_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47cost_45calculator_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47cost_45calculator_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/resource/cost-calculator.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47_91category_93_46vueMeta?.name ?? "resource-global-countryName-category",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47_91category_93_46vueMeta?.path ?? "/resource/global/:countryName/:category",
    file: "/root/workspace/whale-homepage_AeEf/pages/resource/global/[countryName]/[category].vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47_91category_93_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47_91category_93_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47_91category_93_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/resource/global/[countryName]/[category].vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47index_46vueMeta?.name ?? "resource-global-countryName",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47index_46vueMeta?.path ?? "/resource/global/:countryName",
    file: "/root/workspace/whale-homepage_AeEf/pages/resource/global/[countryName]/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/resource/global/[countryName]/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47overview_46vueMeta?.name ?? "resource-global-countryName-overview",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47overview_46vueMeta?.path ?? "/resource/global/:countryName/overview",
    file: "/root/workspace/whale-homepage_AeEf/pages/resource/global/[countryName]/overview.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47overview_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47overview_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47_91countryName_93_47overview_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/resource/global/[countryName]/overview.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47index_46vueMeta?.name ?? "resource-global",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47index_46vueMeta?.path ?? "/resource/global",
    file: "/root/workspace/whale-homepage_AeEf/pages/resource/global/index.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47index_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47index_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47global_47index_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/resource/global/index.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47wage_45calculator_46vueMeta?.name ?? "resource-wage-calculator",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47wage_45calculator_46vueMeta?.path ?? "/resource/wage-calculator",
    file: "/root/workspace/whale-homepage_AeEf/pages/resource/wage-calculator.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47wage_45calculator_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47wage_45calculator_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47resource_47wage_45calculator_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/resource/wage-calculator.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47showcase_47_91id_93_46vueMeta?.name ?? "showcase-id",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47showcase_47_91id_93_46vueMeta?.path ?? "/showcase/:id",
    file: "/root/workspace/whale-homepage_AeEf/pages/showcase/[id].vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47showcase_47_91id_93_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47showcase_47_91id_93_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47showcase_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/showcase/[id].vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47solution_47global_45legitimate_46vueMeta?.name ?? "solution-global-legitimate",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47solution_47global_45legitimate_46vueMeta?.path ?? "/solution/global-legitimate",
    file: "/root/workspace/whale-homepage_AeEf/pages/solution/global-legitimate.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47solution_47global_45legitimate_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47solution_47global_45legitimate_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47solution_47global_45legitimate_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/solution/global-legitimate.vue").then(m => m.default || m)
  },
  {
    name: _47root_47workspace_47whale_45homepage_AeEf_47pages_47solution_47payroll_46vueMeta?.name ?? "solution-payroll",
    path: _47root_47workspace_47whale_45homepage_AeEf_47pages_47solution_47payroll_46vueMeta?.path ?? "/solution/payroll",
    file: "/root/workspace/whale-homepage_AeEf/pages/solution/payroll.vue",
    children: [],
    meta: _47root_47workspace_47whale_45homepage_AeEf_47pages_47solution_47payroll_46vueMeta,
    alias: _47root_47workspace_47whale_45homepage_AeEf_47pages_47solution_47payroll_46vueMeta?.alias || [],
    redirect: _47root_47workspace_47whale_45homepage_AeEf_47pages_47solution_47payroll_46vueMeta?.redirect || undefined,
    component: () => import("/root/workspace/whale-homepage_AeEf/pages/solution/payroll.vue").then(m => m.default || m)
  }
]