import { defineNuxtPlugin } from '#app';
import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin(nuxtApp => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  const { vueApp } = nuxtApp;
  const { appServer } = useRuntimeConfig();
  Sentry.init({
    app: vueApp,
    dsn: appServer === 'LOCAL' 
      ? 'http://27395ada034a4d9484e7be547660448a@sentry.dghire.com/2'
      : 'https://27395ada034a4d9484e7be547660448a@sentry.dghire.com/2',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', /^\//],
      }),
    ],
    trackComponents: true,
    release: '1.0.0',
    tracesSampleRate: 1.0,
    logErrors: true,
  });
});